import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  BACKEND_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  LIMIT,
} from '../../../common/constants';
import ModalRouterPrompt from '../../../components/ModalClosePrompt';
import { CREATE_HOLIDAY, UPDATE_HOLIDAY } from '../graphql/Mutations';

const HolidayModal = (props) => {
  const [form] = Form?.useForm();
  const { RangePicker } = DatePicker;
  const {
    holidayRecord,
    show,
    close,
    executeQuery,
    initialFilter,
    paginationFilter,
    setPaginationFilter,
  } = props;
  const [checked, setChecked] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [isFormValueCahnge, setIsFormValueCahnge] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const defaultValue = dayjs()?.year(initialFilter?.year);
  const setDate =
    holidayRecord?.startDate === holidayRecord?.endDate
      ? dayjs(holidayRecord?.startDate)
      : [dayjs(holidayRecord?.startDate), dayjs(holidayRecord?.endDate)];
  const disabledDate = (current) =>
    (current && current <= dayjs()?.startOf('day')) ||
    current?.year() !== initialFilter?.year;

  const [createHoliday] = useMutation(CREATE_HOLIDAY, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      form?.resetFields();
      close();
      executeQuery({
        variables: {
          filter: {
            ...initialFilter,
            skip: 0,
            limit: paginationFilter?.currentPage
              ? paginationFilter?.pageSize
              : LIMIT,
          },
        },
      });
      setPaginationFilter({ ...paginationFilter, currentPage: 1 });
    },
    onError() {},
  });
  const [editHoliday] = useMutation(UPDATE_HOLIDAY, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      form?.resetFields();
      close();
      executeQuery({
        variables: {
          filter: {
            ...initialFilter,
            skip: 0,
            limit: paginationFilter?.currentPage
              ? paginationFilter?.pageSize
              : LIMIT,
          },
        },
      });
      setPaginationFilter({ ...paginationFilter, currentPage: 1 });
    },
    onError() {},
  });

  useEffect(() => {
    if (holidayRecord) {
      form?.setFieldsValue({
        name: holidayRecord?.name,
        description: holidayRecord?.description,
        date: setDate,
        recurring: holidayRecord?.recurring,
      });
      setChecked(holidayRecord?.startDate !== holidayRecord?.endDate);
    } else {
      form?.setFieldsValue({
        date: defaultValue,
        recurring: false,
      });
    }
  }, [holidayRecord]);

  const onFinish = async (values) => {
    try {
      const startDate = dayjs(
        checked ? values?.date?.[0] : values?.date,
      )?.format(BACKEND_DATE_FORMAT);
      const endDate = dayjs(checked ? values?.date?.[1] : values?.date)?.format(
        BACKEND_DATE_FORMAT,
      );

      if (holidayRecord?.id) {
        editHoliday({
          variables: {
            data: {
              name: values?.name,
              startDate,
              endDate,
              description: values?.description,
              recurring: values?.recurring,
            },
            where: {
              id: holidayRecord?.id,
            },
          },
        });
      } else {
        createHoliday({
          variables: {
            data: {
              name: values?.name,
              startDate,
              endDate,
              description: values?.description,
              recurring: values?.recurring,
            },
          },
        });
      }
      setBtnDisable(true);
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console?.log('Error:', errInfo);
    }
  };

  const onCancel = () => {
    if (isFormValueCahnge) {
      setShowPrompt(true);
    } else {
      form?.resetFields();
      close();
    }
  };

  const handleRangePicker = (e) => {
    setChecked(e?.target?.checked);
    if (!e?.target?.checked) {
      form?.setFieldsValue({
        date: null,
      });
    }
  };

  return (
    <>
      <Modal
        title={!holidayRecord?.id ? 'Create Holiday' : 'Update Holiday'}
        open={show}
        onCancel={() => {
          onCancel();
        }}
        footer={null}
        centered
      >
        <div>
          <Form
            name="control-ref"
            form={form}
            onFinish={onFinish}
            onFieldsChange={() => {
              setBtnDisable(false);
              setIsFormValueCahnge(true);
            }}
            layout="vertical"
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={28}>
                <Form.Item
                  name="name"
                  label="Holiday Name:"
                  rules={[
                    { required: true, message: 'Please Input Holiday Name!' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={28}>
                <Form.Item name="description" label="Description:">
                  <Input showCount maxLength={225} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={28}>
                <Form.Item
                  name="date"
                  label="Date:"
                  rules={[{ required: true, message: 'Please Add Date!' }]}
                >
                  {!checked ? (
                    <DatePicker
                      format={DEFAULT_DATE_FORMAT}
                      disabledDate={disabledDate}
                    />
                  ) : (
                    <RangePicker
                      format={DEFAULT_DATE_FORMAT}
                      disabledDate={disabledDate}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={28}>
                <Form.Item>
                  <Checkbox checked={checked} onChange={handleRangePicker}>
                    Click here incase its a date range
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={28}>
                <Form.Item
                  name="recurring"
                  label="Is Recurring?"
                  rules={[{ required: true, message: 'Please Select One!' }]}
                >
                  <Radio.Group>
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              wrapperCol={{
                offset: 9,
              }}
            >
              <Button type="primary" htmlType="submit" disabled={btnDisable}>
                {!holidayRecord?.id ? 'Create Holiday' : 'Update Holiday'}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <ModalRouterPrompt
          open={showPrompt}
          handleOK={() => {
            setShowPrompt(false);
            close();
          }}
          handleCancel={() => setShowPrompt(false)}
        />
      </Modal>
    </>
  );
};

export default HolidayModal;
