import { Button, Form, Input, Modal, Switch } from 'antd';
import React, { useState } from 'react';
import { CURRENT_STATUS } from '../../../common/constants';
import ModalRouterPrompt from '../../../components/ModalClosePrompt';

const { TextArea } = Input;

const MasterModal = ({ open, onClose, onSubmit, initialValues }) => {
  const [form] = Form.useForm();
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  React.useEffect(() => {
    if (initialValues) {
      const transformedInitialValues = {
        ...initialValues,
        status: initialValues.status === CURRENT_STATUS.ACTIVE,
      };
      form.setFieldsValue(transformedInitialValues);
    } else {
      form.resetFields();
    }
    setIsValueChanged(false);
  }, [initialValues, form]);

  const handleFinish = (values) => {
    const transformedValues = {
      ...values,
      status: values.status ? CURRENT_STATUS.ACTIVE : CURRENT_STATUS.INACTIVE,
    };
    onSubmit(transformedValues);
    form.resetFields();
    onClose();
  };

  return (
    <>
      <Modal
        footer={null}
        open={open}
        title={initialValues ? 'Edit Master' : 'Add Master'}
        onCancel={() => {
          if (isValueChanged) {
            setShowPrompt(true);
          } else {
            onClose();
          }
        }}
        centered
        className="master-modal"
      >
        <div>
          <Form
            form={form}
            layout="vertical"
            initialValues={{ name: '', description: '', status: false }}
            onFinish={handleFinish}
            onFieldsChange={() => setIsValueChanged(true)}
            destroyOnClose
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please Enter Name',
                },
              ]}
            >
              <Input placeholder="Enter Name Here" />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <TextArea
                placeholder="Enter Description Here"
                maxLength={5000}
                showCount
              />
            </Form.Item>
            <Form.Item name="status" label="Status" valuePropName="checked">
              <Switch checkedChildren="Active" unCheckedChildren="InActive" />
            </Form.Item>
            <Form.Item shouldUpdate className="save-master">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValueChanged}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <ModalRouterPrompt
        open={showPrompt}
        handleOK={() => {
          setShowPrompt(false);
          onClose();
          setIsValueChanged(false);
          form?.resetFields();
        }}
        handleCancel={() => setShowPrompt(false)}
      />
    </>
  );
};

export default MasterModal;
