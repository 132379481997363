import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import dayjs from 'dayjs';
import { debounce, filter, flatMap, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  BACKEND_DATE_FORMAT,
  CUSTOM_LEAVE_HALF,
  DEFAULT_DATE_FORMAT,
  LEAVE_FORMAT,
  LEAVE_HALF,
  LEAVE_HALF_TYPE,
  LEAVE_TYPE,
  LEVEL,
} from '../../../common/constants';
import CommonSelect from '../../../components/CommonSelect';
import LoaderComponent from '../../../components/LoaderComponent';

import ModalRouterPrompt from '../../../components/ModalClosePrompt';
import { CREATE_LEAVE, UPDATE_LEAVE } from '../graphql/Mutations';
import { LEAVE_TYPES, TOTAL_DAYS, USERS, USER_LEVEL } from '../graphql/Queries';

const { RangePicker } = DatePicker;
const currentYear = dayjs().year();

const LeaveDrawer = ({
  openLeaveDrawer,
  setOpenLeaveDrawer,
  leaveRecord,
  refetch,
}) => {
  const [form] = Form?.useForm();
  const [userSearch, setUserSearch] = useState('');
  const [internSearch, setInternSearch] = useState('');
  const [btnDisable, setBtnDisable] = useState(true);
  const [isFormValueCahnge, setIsFormValueCahnge] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const watchLeaveDate = Form?.useWatch('date', form);
  const watchLeaveType = Form?.useWatch('leaveHalf', form);
  const watchFromLeaveType = Form?.useWatch('fromLeaveType', form);
  const watchToLeaveType = Form?.useWatch('toLeaveType', form);
  const watchInternId = Form?.useWatch('internId', form);

  const [executeLevelQuery, { data, loading }] = useLazyQuery(USER_LEVEL, {
    fetchPolicy: 'network-only',
    onError() {},
  });
  const [executeLeaveTypeQuery, { data: levelTypeData }] = useLazyQuery(
    LEAVE_TYPES,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );
  const [
    executeTotalDaysQuery,
    { data: totalDaysData, loading: totalDaysLoading },
  ] = useLazyQuery(TOTAL_DAYS, {
    fetchPolicy: 'network-only',
    onError() {
      setBtnDisable(true);
    },
  });
  const [updateLeave] = useMutation(UPDATE_LEAVE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      refetch();
      setOpenLeaveDrawer(false);
    },
    onError: () => {},
  });

  const [createLeave] = useMutation(CREATE_LEAVE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      refetch();
      setOpenLeaveDrawer(false);
    },
    onError: () => {},
  });

  useEffect(() => {
    if (leaveRecord?.id) {
      form?.setFieldsValue({
        leaveHalf:
          // eslint-disable-next-line no-nested-ternary
          dayjs(leaveRecord?.startDate)?.isSame(leaveRecord?.endDate, 'day') &&
          leaveRecord?.fromLeaveType === leaveRecord?.toLeaveType
            ? leaveRecord?.fromLeaveType
            : dayjs(leaveRecord?.startDate)?.isSame(
                  leaveRecord?.endDate,
                  'day',
                ) && leaveRecord?.fromLeaveType !== leaveRecord?.toLeaveType
              ? CUSTOM_LEAVE_HALF?.[0]?.value
              : CUSTOM_LEAVE_HALF?.[1]?.value,
        date: [dayjs(leaveRecord?.startDate), dayjs(leaveRecord?.endDate)],
        leaveType: LEAVE_TYPE?.[1]?.label,
        note: leaveRecord?.reason,
        internId: leaveRecord?.user?.id,
        fromLeaveType: leaveRecord?.fromLeaveType,
        toLeaveType: leaveRecord?.toLeaveType,
      });
    } else {
      form?.setFieldsValue({
        leaveType: LEAVE_TYPE?.[1]?.label,
      });
    }
  }, [leaveRecord?.id]);

  useEffect(() => {
    if (watchInternId) {
      const startDate = dayjs(watchLeaveDate?.[0])
        ?.startOf('day')
        ?.format(BACKEND_DATE_FORMAT);
      const endDate = dayjs(watchLeaveDate?.[1])
        ?.startOf('day')
        ?.format(BACKEND_DATE_FORMAT);

      const variables = {
        data: {
          userId: watchInternId,
          leaveId: leaveRecord?.id || undefined,
          startDate,
          endDate,
          fromLeaveType:
            watchLeaveType === CUSTOM_LEAVE_HALF?.[0]?.value
              ? LEAVE_HALF_TYPE?.[0]?.value
              : watchFromLeaveType ||
                watchLeaveType ||
                leaveRecord?.fromLeaveType,
          toLeaveType:
            watchLeaveType === CUSTOM_LEAVE_HALF?.[0]?.value
              ? LEAVE_HALF_TYPE?.[1]?.value
              : watchToLeaveType || watchLeaveType || leaveRecord?.toLeaveType,
        },
      };

      executeTotalDaysQuery({ variables });
    }
  }, [
    watchInternId,
    watchLeaveDate,
    watchLeaveType,
    watchFromLeaveType,
    watchToLeaveType,
  ]);

  useEffect(() => {
    if (watchLeaveDate?.length > 0 && !leaveRecord?.id) {
      form?.setFieldsValue({
        leaveHalf: LEAVE_HALF?.[0]?.value,
      });
    }
  }, [watchLeaveDate]);

  useEffect(() => {
    if (watchLeaveType === CUSTOM_LEAVE_HALF?.[1]?.value && !leaveRecord?.id) {
      form?.setFieldsValue({
        fromLeaveType: LEAVE_HALF_TYPE?.[0]?.value,
        toLeaveType: LEAVE_HALF_TYPE?.[1]?.value,
      });
    }
  }, [watchLeaveType]);

  useEffect(() => {
    executeLevelQuery();
    executeLeaveTypeQuery();
  }, []);

  const onClose = () => {
    if (isFormValueCahnge) {
      setShowPrompt(true);
    } else {
      form?.resetFields();
      setOpenLeaveDrawer(false);
    }
  };

  const handleUserSearch = debounce((value) => {
    setUserSearch(value);
  }, 500);

  const handleInternSearch = debounce((value) => {
    setInternSearch(value);
  }, 500);

  const handleUserSelect = () => setUserSearch('');

  const handleInternSelect = () => setInternSearch('');

  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }
    return <span>+ {omittedValues?.length} more...</span>;
  };

  const onFinish = async ({ date, notify, note, internId }) => {
    const startDate = dayjs(date?.[0])?.format(BACKEND_DATE_FORMAT);
    const endDate = dayjs(date?.[1])?.format(BACKEND_DATE_FORMAT);
    const leaveInput = {
      endDate,
      fromLeaveType:
        watchLeaveType === CUSTOM_LEAVE_HALF?.[0]?.value
          ? LEAVE_HALF_TYPE?.[0]?.value
          : watchFromLeaveType || watchLeaveType,
      leaveTypeId: levelTypeData?.leaveTypes?.data?.[1]?.id,
      notifyTo: notify,
      reason: note,
      startDate,
      toLeaveType:
        watchLeaveType === CUSTOM_LEAVE_HALF?.[0]?.value
          ? LEAVE_HALF_TYPE?.[1]?.value
          : watchToLeaveType || watchLeaveType,
      totalDays: totalDaysData?.daysAndTypesForLeave?.totalDays,
    };

    try {
      if (leaveRecord?.id) {
        await updateLeave({
          variables: {
            data: {
              ...leaveInput,
              id: leaveRecord?.id,
            },
          },
        });
      } else {
        await createLeave({
          variables: {
            data: {
              ...leaveInput,
              userId: internId,
            },
          },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('Error:', error);
    }
  };

  const disabledDate = (current) => current && current.year() !== currentYear;
  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <Drawer
        title={leaveRecord?.id ? 'Update Leave' : 'Request Leave'}
        width={400}
        onClose={onClose}
        open={openLeaveDrawer}
        maskClosable={false}
        footer={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={() => form?.submit()}
              type="primary"
              disabled={
                btnDisable ||
                totalDaysData?.daysAndTypesForLeave?.totalDays <= 0
              }
            >
              {leaveRecord?.id ? 'Update' : 'Request'}
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFieldsChange={() => {
            setBtnDisable(false);
            setIsFormValueCahnge(true);
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="date"
                label={`Date (${
                  (watchLeaveDate?.length > 0 &&
                    dayjs(watchLeaveDate?.[1]?.add(1, 'day'))?.diff(
                      dayjs(watchLeaveDate?.[0]),
                      'day',
                    )) ||
                  0
                } Days)`}
                rules={[{ required: true, message: 'Please Add Date!' }]}
              >
                <RangePicker
                  className="full-width"
                  format={DEFAULT_DATE_FORMAT}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="leaveType"
                label="Select type of leave you want to apply"
              >
                <Select disabled />
              </Form.Item>
            </Col>
          </Row>
          {totalDaysData?.daysAndTypesForLeave?.totalDays <= 0 && (
            <Row>
              <Col span={24}>
                <Alert
                  className="non-working-days-request"
                  message="Leave request includes non-working days/organization holiday."
                  type="error"
                  showIcon
                />
              </Col>
            </Row>
          )}
          {watchLeaveDate && (
            <Row>
              <Col span={24}>
                <Form.Item
                  name="leaveHalf"
                  label="Select half of leave you want to apply"
                >
                  <Radio.Group
                    options={
                      dayjs(watchLeaveDate?.[0])
                        .startOf('day')
                        ?.isSame(dayjs(watchLeaveDate?.[1]).startOf('day'))
                        ? LEAVE_HALF
                        : CUSTOM_LEAVE_HALF
                    }
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {(watchLeaveType === CUSTOM_LEAVE_HALF?.[1]?.value ||
            (!dayjs(leaveRecord?.startDate)?.isSame(
              leaveRecord?.endDate,
              'day',
            ) &&
              leaveRecord?.fromLeaveType !== leaveRecord?.toLeaveType)) && (
            <div className="intern-leave-half">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="fromLeaveType"
                    label={`From ${dayjs(watchLeaveDate?.[0])
                      .startOf('day')
                      ?.format(LEAVE_FORMAT)}`}
                  >
                    <Select options={LEAVE_HALF_TYPE} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="toLeaveType"
                    label={`To ${dayjs(watchLeaveDate?.[1])
                      .startOf('day')
                      ?.format(LEAVE_FORMAT)}`}
                  >
                    <Select options={LEAVE_HALF_TYPE} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Col span={24}>
              <Form.Item
                name="internId"
                label="Select Intern"
                rules={[
                  {
                    required: true,
                    message: 'Please Choose Intern!',
                  },
                ]}
              >
                <CommonSelect
                  placeholder="Search Employee"
                  showSearch
                  query={USERS}
                  onSearch={handleInternSearch}
                  onChange={handleInternSelect}
                  responsePath="usersAdminLeaves.users"
                  valuePath="id"
                  labelPath="displayName"
                  fetchPolicy="network-only"
                  variables={{
                    filters: {
                      searchStrings: internSearch,
                      levelIds: flatMap([LEVEL?.IN], (levelName) =>
                        map(
                          filter(data?.levels?.levels, { name: levelName }),
                          'id',
                        ),
                      ),
                    },
                  }}
                  useEffectDeps={[internSearch]}
                  virtual={false}
                  maxTagCount={3}
                  maxTagPlaceholder={renderMaxTag}
                  disabled={leaveRecord?.id || !watchLeaveDate?.length > 0}
                />
              </Form.Item>
            </Col>
          </Row>
          {watchInternId &&
            !totalDaysLoading &&
            totalDaysData?.daysAndTypesForLeave?.totalDays > 0 && (
              <div className="number-of-leave-days">
                {`You are requesting for ${
                  totalDaysData?.daysAndTypesForLeave?.totalDays ||
                  leaveRecord?.duration
                } days of leave`}
              </div>
            )}
          <Row>
            <Col span={24}>
              <Form.Item
                name="note"
                label="Note"
                rules={[
                  {
                    required: true,
                    message: 'Please Add Note!',
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  maxLength={255}
                  showCount
                  placeholder="Type here..."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="notify"
                label="Notify"
                rules={[
                  {
                    required: true,
                    message: 'Please Choose Notify!',
                  },
                ]}
              >
                <CommonSelect
                  mode="tags"
                  placeholder="Search Employee"
                  showSearch
                  query={USERS}
                  onSearch={handleUserSearch}
                  onChange={handleUserSelect}
                  responsePath="usersAdminLeaves.users"
                  valuePath="id"
                  labelPath="displayName"
                  fetchPolicy="network-only"
                  variables={{
                    filters: {
                      searchStrings: userSearch,
                      levelIds: flatMap(
                        [LEVEL?.L1, LEVEL?.L2, LEVEL?.L3, LEVEL?.L4],
                        (levelName) =>
                          map(
                            filter(data?.levels?.levels, { name: levelName }),
                            'id',
                          ),
                      ),
                    },
                  }}
                  useEffectDeps={[userSearch]}
                  virtual={false}
                  maxTagCount={3}
                  maxTagPlaceholder={renderMaxTag}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
      <ModalRouterPrompt
        open={showPrompt}
        handleOK={() => {
          setShowPrompt(false);
          setOpenLeaveDrawer(false);
        }}
        handleCancel={() => setShowPrompt(false)}
      />
    </>
  );
};
export default LeaveDrawer;
