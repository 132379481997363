import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  TimePicker,
  Tooltip,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import { parseInt, replace, trimStart } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { AppContext } from '../../../AppContext';
import { toast } from '../../../apollo';
import {
  DEFAULT_DATE_FORMAT,
  HR_DEPARTMENT_DETAILS,
  LEAVE_DATE_FORMAT,
  LEAVE_DATE_TIME_FORMAT,
  REGEX,
  ROUTES_MODULES_KEY,
} from '../../../common/constants';
import {
  combineDateTimeFormat,
  fileUpload,
  formValidatorRules,
  getBase64,
} from '../../../common/utils';
import CommonButton from '../../../components/CommonButton';
import CommonInput from '../../../components/CommonInput';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_SIGNED_URL } from '../../../components/graphql/Mutation';
import {
  CREATE_EVENT,
  DUPLICATE_EVENT,
  UPDATE_EVENT,
} from '../graphql/Mutation';
import { GET_CATEGORY, GET_EVENT_TYPES } from '../graphql/Queries';
// comment for future use
// let ministryDebounceJob;
const EventForm = ({ isEdit, isDuplicate, initialValues, data }) => {
  // comment for future use
  // const initialMinistryFilter = {
  //   skip: 0,
  //   limit: 10,
  //   status: false,
  // };
  const { eventId } = useParams();
  const { required, email } = formValidatorRules;
  const { TextArea } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const history = useHistory();
  const { dispatch } = useContext(AppContext);
  const [isVirtualEvent, setIsVirtualEvent] = useState(false);
  const [headerImageUrl, setHeaderImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  // comment for future use
  // const [entryFee, setEntryFee] = useState(false);
  // const [donationFee, setDonationFee] = useState(false);
  // const [additionalPhotos, setAdditionalPhotos] = useState([]);
  // const [additionalPdfs, setAdditionalPdfs] = useState([]);
  // const [isSpecialEvent, setIsSpecialEvent] = useState(false);
  // const [onMinistryConfirm, setOnMinistryConfirm] = useState(false);
  // const [isTableSponsoredChecked, setIsTableSponsoredChecked] = useState(false);
  // const [isMinistryEnd, setIsMinistryEnd] = useState(false);
  // const [ministries, setMinistries] = useState([]);
  // const [ministriesLoading, setMinistriesLoading] = useState(false);
  // const [ministriesSearchFlag, setMinistriesSearchFlag] = useState(false);
  // const [ministriesDebounceCall, setMinistriesDebounceCall] = useState(0);
  // const isDesktopViewPort = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const { loading: categoryLoading, data: categoryData } = useQuery(
    GET_CATEGORY,
    {
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          search: '',
        },
      },
    },
  );

  const { loading: eventTypeLoading, data: eventTypesData } = useQuery(
    GET_EVENT_TYPES,
    {
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          search: '',
        },
      },
    },
  );
  // comment for future use
  // const [fetchMinistries] = useLazyQuery(GET_MINISTRIES, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: (res) => {
  //     if (
  //       res?.ministries?.ministries?.length < LIMIT ||
  //       ministries?.length === res?.ministries?.count
  //     ) {
  //       setIsMinistryEnd(true);
  //     }
  //     if (ministriesSearchFlag) {
  //       setMinistries([...res?.ministries?.ministries]);
  //     } else {
  //       if ((isEdit || isDuplicate) && data?.event?.data?.ministry) {
  //         const tempMinistries = [
  //           ...data?.event?.data?.ministry,
  //           ...res?.ministries?.ministries,
  //         ];
  //         setMinistries(uniqBy([...ministries, ...tempMinistries], 'id'));
  //         setMinistriesLoading(false);
  //         return;
  //       }
  //       setMinistries(
  //         uniqBy([...ministries, ...res?.ministries?.ministries], 'id'),
  //       );
  //     }
  //     setMinistriesLoading(false);
  //   },
  //   onError() {
  //     setMinistriesLoading(false);
  //   },
  // });

  // comment for future use
  // useEffect(() => {
  //   fetchMinistries({
  //     variables: {
  //       filter: initialMinistryFilter,
  //     },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // comment for future use
  // const [eventType, setEventType] = useState();

  const [createEvent] = useMutation(CREATE_EVENT);
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const [duplicateEvent] = useMutation(DUPLICATE_EVENT);

  // comment for future use
  // useEffect(() => {
  //   if (additionalPdfs?.length === 5) {
  //     message.error('You can only upload upto 5 pdfs only');
  //   }
  // }, [additionalPdfs]);

  const [fetchSignedUrl] = useMutation(GET_SIGNED_URL);

  const handleUploadImage = async (info) => {
    const imageName = info?.file?.name;
    const fileName = replace(imageName, new RegExp(' ', 'g'), '_');
    const extension = imageName.slice(imageName.lastIndexOf('.') + 1);
    try {
      return fetchSignedUrl({
        variables: {
          data: {
            key: `event/${fileName}`,
            contentType: info.file.type,
            extension,
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error while uploading image', error);
    }
  };

  // comment for future use
  // const eventTypeChange = (typeId) => {
  //   const eventTypes = eventTypesData?.eventTypes?.eventTypes?.find(
  //     (type) => parseInt(type?.id) === typeId,
  //   );
  //   if (eventTypes && eventTypesData) {
  //     setIsSpecialEvent(eventTypes?.key === EVENT_TYPES.IGNITE);
  //     setEventType(eventTypes.key);
  //   }
  // };

  // comment for future use
  // const onAddMinistry = () =>
  //   confirm({
  //     title: `Are you sure, you want to save event and want to add ministry?`,
  //     okText: 'Yes',
  //     cancelText: 'No',
  //     centered: true,
  //     onOk: () => {
  //       form.submit();
  //       setOnMinistryConfirm(true);
  //     },
  //     onCancel: () => {
  //       setOnMinistryConfirm(false);
  //     },
  //   });

  // comment for future use
  // const onEventStartDateChange = (startDate) => {
  //   if (startDate === null) {
  //     form.setFieldsValue({
  //       feedbackStartDate: null,
  //       feedbackCloseDate: null,
  //     });
  //   } else {
  //     form.setFieldsValue({
  //       feedbackStartDate: startDate,
  //       feedbackCloseDate: moment(startDate).add(7, 'days'),
  //     });
  //   }
  // };

  const onFinish = async (formValues) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    setUploading(true);
    let registrationStartDate;
    let registrationEndDate;
    try {
      const startDate = combineDateTimeFormat(
        formValues?.start_date,
        formValues?.start_time,
      );
      const feedbackStartDate =
        formValues?.feedbackStartDate &&
        dayjs(
          formValues?.feedbackStartDate?.format(LEAVE_DATE_TIME_FORMAT),
        ).toISOString();

      const feedbackCloseDate =
        formValues?.feedbackCloseDate &&
        dayjs(
          formValues?.feedbackCloseDate?.format(LEAVE_DATE_TIME_FORMAT),
        ).toISOString();
      const endDate = combineDateTimeFormat(
        formValues?.start_date,
        formValues?.end_time,
      );
      const availabilityDate = formValues.availabilityDate
        ? dayjs(
            formValues?.availabilityDate?.format(LEAVE_DATE_TIME_FORMAT),
          ).toISOString()
        : null;
      if (
        formValues?.registration_start_date &&
        formValues?.registration_start_time
      ) {
        registrationStartDate = combineDateTimeFormat(
          formValues?.registration_start_date,
          formValues?.registration_start_time,
        );
      } else if (
        formValues?.registration_start_date &&
        !formValues?.registration_start_time
      ) {
        registrationStartDate = dayjs(
          `${formValues?.registration_start_date?.format(
            LEAVE_DATE_FORMAT,
          )} ${formValues?.registration_start_date?.format('h:mm a')}`,
        ).toISOString();
      }

      if (
        formValues?.registration_start_date &&
        formValues?.registration_close_date
      ) {
        const isBefore = dayjs(formValues?.registration_close_date).isBefore(
          dayjs(formValues?.registration_start_date),
        );
        if (isBefore) {
          toast({
            message:
              'Registration Starting Time must be less than Closing Time',
            type: 'error',
          });
          return false;
        }
      }
      registrationStartDate = registrationStartDate || null;
      if (
        formValues?.registration_close_date &&
        formValues?.registration_close_time
      ) {
        registrationEndDate = combineDateTimeFormat(
          formValues?.registration_close_date,
          formValues?.registration_close_time,
        );
      } else if (
        formValues?.registration_close_date &&
        !formValues?.registration_close_time
      ) {
        registrationEndDate = dayjs(
          `${formValues?.registration_close_date?.format(
            LEAVE_DATE_FORMAT,
          )} ${formValues?.registration_close_date?.format('h:mm a')}`,
        ).toISOString();
      }

      const folderId = uuid();
      const variables = {
        name: formValues?.name,
        categoryId: categoryData?.eventCategories?.eventCategories?.[1]?.id,
        typeId: eventTypesData?.eventTypes?.eventTypes?.[1]?.id,
        contacts: formValues?.contacts?.[0],
        startDate,
        endDate,
        ministries: formValues?.ministries,
        additionalPhotos:
          formValues?.additionalPhotos || data?.event?.data?.additionalPhotos,
        suiteNo: formValues?.suiteNo || null,
        notes: formValues?.notes || null,
        description: formValues?.description || null,
        availabilityDate,
        noOfGuests: parseInt(formValues?.noOfGuests) || null,
        noOfWaitlist: parseInt(formValues?.noOfWaitList) || 0,
        maxAttendees: parseInt(formValues?.maxAttendees) || null,
        registrationStartDate:
          registrationStartDate ||
          null ||
          data?.event?.data?.registrationStartDate,
        registrationCloseDate:
          registrationEndDate ||
          null ||
          data?.event?.data?.registrationCloseDate,
        allowSpouse: formValues?.allowSpouse || false,
        city: isVirtualEvent ? null : formValues?.city,
        isVirtualEvent: formValues?.isVirtualEvent || false,
        virtualEventUrl: formValues?.virtualEventUrl || null,
        isVisible: formValues?.isVisible || false,
        locationName: isVirtualEvent ? null : formValues?.locationName,
        state: isVirtualEvent ? null : formValues?.state,
        streetAddress: isVirtualEvent ? null : formValues?.streetAddress,
        zipcode: isVirtualEvent ? null : formValues?.zipCode,
        donationUrl: formValues?.donationUrl || null,
        paymentUrl: formValues?.paymentUrl || null,
        isDonationFee: formValues?.isDonationFee || false,
        donationFee: Number(formValues?.donationFee) || null,
        isEntryFee: formValues?.isEntryFee || false,
        entryFee: Number(formValues?.entryFee) || null,
        headerImage: headerImageUrl || data?.event?.data?.headerImage,
        isTableSponsored: formValues?.isTableSponsored || false,
        pricePerTable: Number(formValues?.pricePerTable) || null,
        seatsPerTable: Number(formValues?.seatsPerTable) || null,
        feedbackStartDate: feedbackStartDate || null,
        feedbackCloseDate: feedbackCloseDate || null,
        folderId,
        pdfDetails: formValues?.additionalPdfs || null,
      };
      if (formValues?.headerImage) {
        if (typeof formValues?.headerImage === 'object') {
          await handleUploadImage(formValues?.headerImage).then(async (res) => {
            const signedImageUrl = res?.data?.getSignedUrl?.signedRequest;
            const getUrl = res?.data?.getSignedUrl?.url;
            await fileUpload(signedImageUrl, formValues?.headerImage?.file);
            variables.headerImage = getUrl;
          });
        }
      }

      // comment for future use
      // if (formValues?.additionalPhotos) {
      //   const photos = isEdit ? [...additionalPhotos] : [];
      //   await Promise.all(
      //     map(formValues?.additionalPhotos, async (item) => {
      //       if (typeof item?.imageUrl === 'object') {
      //         await handleUploadImage(item?.imageUrl).then(async (res) => {
      //           const signedImageUrl = res?.data?.getSignedUrl?.signedRequest;
      //           const getUrl = res?.data?.getSignedUrl?.url;
      //           await fileUpload(signedImageUrl, item?.imageUrl?.file);
      //           photos.push({
      //             imageUrl: getUrl,
      //             shortDescription: item?.shortDescription,
      //           });
      //           removeImage(photos, (items) => !has(items, 'shortDescription'));
      //           variables.additionalPhotos = photos;
      //         });
      //       }
      //     }),
      //   );
      // }

      // comment for future use
      // if (formValues?.additionalPdfs) {
      //   const pdfs = [];
      //   await Promise.all(
      //     map(formValues.additionalPdfs, async (item) => {
      //       if (typeof item?.imageUrl === 'object') {
      //         await handleUploadImage(item?.imageUrl).then(async (res) => {
      //           const signedImageUrl = res?.data?.getSignedUrl?.signedRequest;
      //           const getUrl = res?.data?.getSignedUrl?.url;
      //           await fileUpload(signedImageUrl, item?.imageUrl?.file);
      //           pdfs.push({
      //             imageUrl: getUrl,
      //             name: item?.name,
      //           });
      //           variables.pdfDetails = pdfs;
      //         });
      //       }
      //     }),
      //   );
      // }

      if (isEdit) {
        const response = await updateEvent({
          variables: {
            data: {
              ...variables,
            },
            updateEventId: eventId,
          },
        });
        if (response?.data?.updateEvent) {
          form.resetFields();
          history.push(`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}`);
        }
      } else if (isDuplicate) {
        const response = await duplicateEvent({
          variables: {
            data: {
              ...variables,
            },
            duplicateEventId: eventId,
          },
        });
        if (response?.data?.duplicateEvent) {
          form.resetFields();
          history.push(`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}`);
        }
      } else {
        const response = await createEvent({
          variables: {
            data: {
              ...variables,
            },
          },
        });
        if (response?.data?.createEvent) {
          form.resetFields();
          history.push(`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}`);
        }
      }
      // comment for future use
      // if (onMinistryConfirm) {
      //   history.push(`${ROUTES.MINISTRY_MANAGEMENT}/create`);
      // } else {
      //   history.push(`${ROUTES.EVENT_MANAGEMENT}`);
      // }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err =>', err);
    } finally {
      setUploading(false);
    }
  };

  // comment for future use
  // const onMinistryScroll = (event) => {
  //   setMinistriesSearchFlag(false);
  //   if (ministryDebounceJob) {
  //     ministryDebounceJob?.cancel();
  //   }
  //   ministryDebounceJob = debounce(() => {
  //     const { target } = event;
  //     const { scrollTop, scrollHeight, offsetHeight } = target || {};
  //     const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
  //     if (scrolledToBottom && !isMinistryEnd) {
  //       setMinistriesDebounceCall((prevState) => prevState + 1);
  //       setMinistriesLoading(true);
  //       fetchMinistries({
  //         variables: {
  //           filter: {
  //             ...initialMinistryFilter,
  //             skip: (ministriesDebounceCall + 1) * LIMIT,
  //           },
  //         },
  //       });
  //     }
  //   }, 500);
  //   ministryDebounceJob();
  // };

  // comment for future use
  // const handleMinistrySearch = (value) => {
  //   setMinistriesSearchFlag(true);
  //   if (value) {
  //     setMinistriesLoading(true);
  //     fetchMinistries({
  //       variables: {
  //         filter: {
  //           ...initialMinistryFilter,
  //           search: value,
  //         },
  //       },
  //     });
  //   } else {
  //     setMinistriesLoading(false);
  //     fetchMinistries({
  //       variables: {
  //         filter: {
  //           ...initialMinistryFilter,
  //           search: value,
  //         },
  //       },
  //     });
  //   }
  // };

  // comment for future use
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounceMinistrySearchHandler = useCallback(
  //   debounce(handleMinistrySearch, 1000),
  //   [],
  // );

  // comment for future use
  // const handleMinistryClear = () => {
  //   setMinistriesSearchFlag(false);
  //   setMinistries([]);
  //   fetchMinistries({
  //     variables: { filter: initialMinistryFilter },
  //   });
  // };

  // comment for future use
  // const handleMinistryBlur = () => {
  //   setMinistriesDebounceCall(0);
  //   setIsMinistryEnd(false);
  //   setMinistriesSearchFlag(false);
  //   setMinistries([]);
  //   fetchMinistries({
  //     variables: {
  //       filter: initialMinistryFilter,
  //     },
  //   });
  // };

  // comment for future use
  // const handleMinistrySelect = () => {
  //   setIsMinistryEnd(false);
  //   setMinistriesSearchFlag(false);
  //   fetchMinistries({
  //     variables: {
  //       filter: initialMinistryFilter,
  //     },
  //   });
  // };

  useEffect(() => {
    if (initialValues?.isVirtualEvent) {
      setIsVirtualEvent(true);
    } else {
      setIsVirtualEvent(false);
    }
    // comment for future use
    // if (initialValues?.additionalPhotos) {
    //   setAdditionalPhotos([...initialValues?.additionalPhotos]);
    // }
    if (initialValues?.headerImage) {
      setHeaderImageUrl(initialValues?.headerImage);
    }
  }, [initialValues]);

  if (categoryLoading || eventTypeLoading) {
    return <LoaderComponent />;
  }

  const updatedInitialValues = {
    ...initialValues,
    categoryId: categoryData?.eventCategories?.eventCategories?.[1]?.value,
    typeId: eventTypesData?.eventTypes?.eventTypes?.[1]?.value,
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        initialValues={
          isEdit || isDuplicate
            ? updatedInitialValues
            : {
                categoryId:
                  categoryData?.eventCategories?.eventCategories?.[1]?.value,
                typeId: eventTypesData?.eventTypes?.eventTypes?.[1]?.value,
                maxAttendees: parseInt(200),
                contacts: [
                  {
                    name: HR_DEPARTMENT_DETAILS?.HR,
                    type: HR_DEPARTMENT_DETAILS?.TYPE,
                    phone_no: HR_DEPARTMENT_DETAILS?.MOBILE_NUMBER,
                    email: HR_DEPARTMENT_DETAILS?.EMAIL,
                  },
                ],
              }
        }
        className="event-add-edit-form"
        onFinish={onFinish}
        onFieldsChange={() => {
          setBtnDisable(false);
          dispatch({
            type: 'SET_SHOW_PROMPT',
            data: true,
          });
        }}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Category" name="categoryId">
              <Select disabled>
                {categoryData?.eventCategories?.eventCategories?.map(
                  (category) => (
                    <Option key={category.id} value={category.value}>
                      {category.label}
                    </Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Type" name="typeId">
              <Select disabled>
                {eventTypesData?.eventTypes?.eventTypes?.map((type) => (
                  <Option key={type.id} value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Title"
              name="name"
              rules={[
                required,
                { whitespace: true, message: 'Please Add Title!' },
              ]}
            >
              <CommonInput placeholder="Title" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="start_date"
              label="Select Date"
              required
              rules={[
                {
                  validator(rule, value, callback) {
                    if (!value) {
                      callback('Required');
                    } else if (value.isBefore(dayjs().startOf('day'))) {
                      callback('Past date cannot be selected');
                    }
                    callback();
                  },
                },
              ]}
            >
              <DatePicker
                showToday={false}
                disabledDate={(current) =>
                  current.isBefore(dayjs().startOf('day'))
                }
                format={DEFAULT_DATE_FORMAT}
                // comment for future use
                // onChange={onEventStartDateChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="start_time"
              label="Start Time"
              rules={[
                required,
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (!getFieldValue('start_date')) {
                      return Promise.reject(new Error('Date must be selected'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              dependencies={['start_date']}
            >
              <TimePicker placeholder="Start Time" use12Hours format="h:mm a" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="end_time"
              label="End Time"
              dependencies={['start_time', 'start_date']}
              rules={[
                required,
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (
                      !getFieldValue('start_date') ||
                      !getFieldValue('start_time')
                    ) {
                      return Promise.reject(
                        new Error('Date and Start Time must be selected'),
                      );
                    }
                    const startTime = getFieldValue('start_time').set({
                      year: getFieldValue('start_date').get('year'),
                      month: getFieldValue('start_date').get('month'),
                      date: getFieldValue('start_date').get('date'),
                    });
                    const endTime = value.set({
                      year: getFieldValue('start_date').get('year'),
                      month: getFieldValue('start_date').get('month'),
                      date: getFieldValue('start_date').get('date'),
                    });
                    if (endTime.isBefore(startTime)) {
                      return Promise.reject(
                        new Error('End time must be after Start time'),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <TimePicker placeholder="End Time" use12Hours format="h:mm a" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="isVirtualEvent"
              label="Virtual Event"
              valuePropName="checked"
            >
              <Switch
                onChange={(e) => {
                  setIsVirtualEvent(e);
                }}
              />
            </Form.Item>
          </Col>
          {isVirtualEvent && (
            <Col span={6}>
              <Form.Item
                name="virtualEventUrl"
                label="Virtual Event URL"
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (
                        trimStart(value) &&
                        !trimStart(value).match(REGEX.WEB_URL)
                      ) {
                        return Promise.reject(
                          new Error('should be a valid url'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <CommonInput placeholder="Virtual Event URL" allowClear />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          {!isVirtualEvent && (
            <>
              <Col span={6}>
                <Form.Item
                  name="locationName"
                  label="Location Name"
                  rules={[
                    required,
                    { whitespace: true, message: 'Please Add Location Name!' },
                  ]}
                >
                  <CommonInput placeholder="Location Name" allowClear />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="streetAddress"
                  label="Street Address"
                  rules={[
                    required,
                    { whitespace: true, message: 'Please Add Street Address!' },
                  ]}
                >
                  <CommonInput placeholder="Street Address" allowClear />
                </Form.Item>
              </Col>
              <Col className="d-none" span={6}>
                <Form.Item name="suiteNo" label="Suite Number (Optional)">
                  <CommonInput placeholder="Suite Number" allowClear />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    required,
                    { whitespace: true, message: 'Please Add City!' },
                  ]}
                >
                  <CommonInput placeholder="City" allowClear />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    required,
                    { whitespace: true, message: 'Please Add State!' },
                  ]}
                >
                  <CommonInput placeholder="State" allowClear />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="zipCode"
                  label="Zip code"
                  rules={[
                    required,
                    () => ({
                      validator(rule, value) {
                        if (value && !REGEX.ZIPCODE.test(value)) {
                          return Promise.reject(
                            new Error('should be a valid zipcode'),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <CommonInput placeholder="Zip code" allowClear />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="notes" label="Event Notes">
              <CommonInput placeholder="Event Notes" allowClear />
            </Form.Item>
          </Col>
          <Col className="d-none" span={6}>
            <Form.Item
              name="headerImage"
              label="Upload Image"
              className="upload-event-header-image"
              // comment for future use
              // rules={[required]}
            >
              <Upload
                accept="image/x-png, image/jpeg, image/jpg"
                beforeUpload={() => false}
                className="event-header-image"
                listType="picture-card"
                showUploadList={false}
                onChange={(info) => {
                  getBase64(info?.file, (imageUrl) =>
                    setHeaderImageUrl(imageUrl),
                  );
                  return info;
                }}
              >
                {headerImageUrl ? (
                  <img
                    className="header-image"
                    src={headerImageUrl}
                    alt="headerImage"
                  />
                ) : (
                  <h3 className="ant-upload-text d-flex align-center justify-center h-full">
                    Add Event Header Photo Here
                  </h3>
                )}
                {headerImageUrl && (
                  <CommonButton
                    type="link"
                    danger
                    onClick={(e) => {
                      e.stopPropagation();
                      form.setFieldsValue({
                        headerImage: [],
                      });
                      setHeaderImageUrl(null);
                    }}
                    className="header-img-btn"
                  >
                    <DeleteOutlined />
                  </CommonButton>
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label="Description">
              <TextArea rows={4} placeholder="Description" allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="contacts">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((key, name, ...restField) => (
                <>
                  <Row key={key} gutter={16}>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        label="Contact Person name"
                        rules={[
                          required,
                          { whitespace: true, message: 'Required' },
                        ]}
                      >
                        <CommonInput
                          placeholder="Contact Person name"
                          allowClear
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'type']}
                        label="Contact Type"
                        rules={[required]}
                      >
                        <Select disabled>
                          {eventTypesData?.eventTypes?.eventTypes?.map(
                            (type) => (
                              <Option key={type.id} value={type.value}>
                                {type.label}
                              </Option>
                            ),
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'phone_no']}
                        label="Contact Person Phone"
                        rules={[
                          required,
                          () => ({
                            validator(rule, value) {
                              if (value) value.split(' ').join('');
                              const numberPattern = REGEX.PHONE;
                              if (value && !numberPattern.test(value)) {
                                return Promise.reject(
                                  new Error('should be a valid phone number'),
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <CommonInput
                          allowClear
                          placeholder="9999988888"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, 'email']}
                        label="Contact Person Email"
                        rules={[required, email]}
                      >
                        <CommonInput
                          placeholder="Contact Person Email"
                          allowClear
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <div className="d-none" flex="none">
                      <Tooltip title="Remove contact info">
                        <CommonButton
                          type="link"
                          danger
                          className="remove-upload-file"
                          onClick={() => remove(name)}
                        >
                          <DeleteOutlined />
                        </CommonButton>
                      </Tooltip>
                    </div>
                  </Row>
                </>
              ))}
              <Form.Item className="d-none">
                <Row>
                  <h4
                    className="pointer"
                    onClick={() => {
                      add();
                    }}
                  >
                    + Add another contact
                  </h4>
                </Row>
              </Form.Item>
            </div>
          )}
        </Form.List>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="allowSpouse"
              label="Allow Spouse"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="availabilityDate" label="Availability Date">
              <DatePicker
                showToday={false}
                disabledDate={(current) =>
                  current.isBefore(dayjs().startOf('day'))
                }
                format={DEFAULT_DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="noOfGuests" label="Number of Guest (Optional)">
              <InputNumber
                className="select-user-hours number-of-guest"
                placeholder="Number of Guest"
                allowClear
                max={10}
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="maxAttendees"
              rules={[required]}
              label="Max Event Attendance"
            >
              <CommonInput placeholder="Max Event Attendance" allowClear />
            </Form.Item>
          </Col>
        </Row>
        {
          // comment for future use
          /* <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="isEntryFee"
              label="Entry Fee"
              valuePropName="checked"
            >
              <Switch onChange={(e) => setEntryFee(e)} />
            </Form.Item>
          </Col>
          {(data?.event?.data?.isEntryFee || entryFee) && (
            <Col span={6}>
              <Form.Item
                name="entryFee"
                label="Entry Fee"
                rules={[required, number]}
              >
                <CommonInput prefix="$" placeholder="Entry Fee" allowClear />
              </Form.Item>
            </Col>
          )}
          <Col span={6}>
            <Form.Item
              name="isDonationFee"
              label="Enable Donation"
              valuePropName="checked"
            >
              <Switch onChange={(e) => setDonationFee(e)} />
            </Form.Item>
          </Col>
          {(data?.event?.data?.isDonationFee || donationFee) && (
            <Col span={6}>
              <Form.Item
                name="donationFee"
                label="Initial Donation (Optional)"
                rules={[number]}
              >
                <CommonInput
                  prefix="$"
                  placeholder="Initial Donation"
                  allowClear
                />
              </Form.Item>
            </Col>
          )}
        </Row> */
        }
        {
          // comment for future use
          /* <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="noOfWaitList"
              label="Number of Waitlist"
              rules={[number]}
            >
              <CommonInput placeholder="Number of Waitlist" allowClear />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewPort ? 6 : 12}>
            <Form.Item
              name="isVisible"
              label="No of Max Attendee on Mobile App"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row> */
        }
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="registration_start_date"
              label="Registration Start Date"
            >
              <DatePicker
                disabledDate={(current) =>
                  current.isBefore(dayjs().startOf('day'))
                }
                showToday={false}
                format={DEFAULT_DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="registration_close_date"
              label="Registration Closing Date"
            >
              <DatePicker
                disabledDate={(current) =>
                  current.isBefore(dayjs().startOf('day'))
                }
                showToday={false}
                format={DEFAULT_DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="registration_start_time"
              label="Registration Start Time"
            >
              <TimePicker
                placeholder="Registration Start Time"
                use12Hours
                format="h:mm a"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="registration_close_time"
              label="Registration Closing Time"
            >
              <TimePicker
                placeholder="Registration Closing Time"
                use12Hours
                format="h:mm a"
              />
            </Form.Item>
          </Col>
        </Row>
        {
          // comment for future use
          /* {(isSpecialEvent ||
          data?.event?.data?.eventType === EVENT_TYPES.IGNITE) && (
          <Row className="d-flex align-center" gutter={16}>
            <Col span={6}>
              <Form.Item
                name="isTableSponsored"
                label="Table Sponsorship"
                valuePropName="checked"
              >
                <Switch onChange={(e) => setIsTableSponsoredChecked(e)} />
              </Form.Item>
            </Col>
            {(isTableSponsoredChecked ||
              data?.event?.data?.isTableSponsored) && (
              <Col span={6}>
                <Form.Item
                  name="pricePerTable"
                  label="Price Per Table"
                  rules={[required, number]}
                >
                  <Input
                    className="col"
                    prefix="$"
                    placeholder="Price Per Table"
                    allowClear
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={6}>
              <Form.Item
                name="seatsPerTable"
                label="Seats per Table"
                rules={isTableSponsoredChecked ? [required, number] : [number]}
              >
                <Input
                  className="col"
                  placeholder="Seats per Table"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <h3 className="mb-0">= 1 Table</h3>
            </Col>
          </Row>
        )} */
        }
        {
          // comment for future use
          /* {(data?.event?.data?.isEntryFee || entryFee) && (
          <Row>
            <Col span={24}>
              <Form.Item
                name="paymentUrl"
                label="Payment URL"
                rules={[
                  required,
                  () => ({
                    validator(rule, value) {
                      if (
                        trimStart(value) &&
                        !trimStart(value).match(REGEX.WEB_URL)
                      ) {
                        return Promise.reject(
                          new Error('should be a valid url'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <CommonInput placeholder="Payment URL" allowClear />
              </Form.Item>
            </Col>
          </Row>
        )} */
        }
        {
          // comment for future use
          /* <Form.List name="additionalPhotos">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field, index) => (
                <Row key={key} gutter={16}>
                  <Col span={isDesktopViewPort ? 12 : 24}>
                    <Form.Item
                      name={[field.name, 'imageUrl']}
                      fieldKey={[field.fieldKey, 'imageUrl']}
                      rules={[required]}
                    >
                      <Upload
                        accept="image/x-png, image/jpeg, image/jpg"
                        beforeUpload={() => false}
                        className="event-header-image "
                        listType="picture-card"
                        showUploadList={false}
                        onChange={(info) => {
                          getBase64(info?.file, (imageUrl) => {
                            const additionalPhoto = [...additionalPhotos];
                            additionalPhotos[key] = {
                              imageUrl,
                            };
                            setAdditionalPhotos([
                              ...additionalPhotos,
                              additionalPhoto,
                            ]);
                          });
                        }}
                      >
                        {additionalPhotos?.[field.name] &&
                        additionalPhotos?.[field.name]?.imageUrl ? (
                          <img
                            src={
                              additionalPhotos?.[field.name]?.imageUrl ||
                              data?.event?.data?.additionalPhotos?.[index]
                                ?.imageUrl
                            }
                            className="additional-photo"
                            alt="additional"
                          />
                        ) : (
                          <h2 className="d-flex align-center justify-center ">
                            {' '}
                          </h2>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>

                  <Col span={isDesktopViewPort ? 12 : 24}>
                    <Form.Item
                      name={[field.name, 'shortDescription']}
                      fieldKey={[field.fieldKey, 'shortDescription']}
                      rules={[
                        required,
                        { whitespace: true, message: 'Required' },
                      ]}
                    >
                      <TextArea
                        rows={6}
                        placeholder="Short Description for additional photo"
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <div flex="none">
                    <Tooltip title="Remove photo">
                      <CommonButton
                        type="link"
                        danger
                        onClick={() => {
                          remove(field.name);
                          removeImage(
                            additionalPhotos,
                            (i) => i === additionalPhotos[field.name],
                          );
                        }}
                        className="remove-upload-file"
                      >
                        <DeleteOutlined />
                      </CommonButton>
                    </Tooltip>
                  </div>
                </Row>
              ))}
              <Form.Item>
                <div className="form-group row">
                  <h4
                    className="pointer"
                    onClick={() => {
                      add();
                    }}
                  >
                    + Add another photos
                  </h4>
                </div>
              </Form.Item>
            </div>
          )}
        </Form.List> */
        }
        {
          // comment for future use
          /* <Form.List name="additionalPdfs">
          {(fields, { add, remove }) => (
            <div>
              {fields.map((field, index) => (
                <Row key={key} gutter={16}>
                  <Col span={isDesktopViewPort ? 12 : 24}>
                    <Form.Item
                      name={[field.name, 'name']}
                      fieldKey={[field.fieldKey, 'name']}
                      rules={[
                        required,
                        { whitespace: true, message: 'Required' },
                      ]}
                    >
                      <TextArea rows={6} placeholder="PDF Name" />
                    </Form.Item>
                  </Col>

                  <Col span={isDesktopViewPort ? 12 : 24}>
                    <Form.Item
                      name={[field.name, 'imageUrl']}
                      fieldKey={[field.fieldKey, 'imageUrl']}
                      rules={[required]}
                    >
                      <Upload
                        accept=".pdf"
                        beforeUpload={() => false}
                        disabled={additionalPdfs.length === 5}
                        className="event-header-image"
                        listType="picture-card"
                        showUploadList={false}
                        onChange={(info) => {
                          getBase64(info?.file, (imageUrl) => {
                            const pdfs = {
                              imageUrl,
                              name: info?.file?.name,
                            };
                            setAdditionalPdfs([...additionalPdfs, pdfs]);
                          });
                        }}
                      >
                        {eventId ? (
                          <>
                            {((additionalPdfs?.[field.name] &&
                              additionalPdfs?.[field.name]?.name) ||
                              data?.event?.data?.pdfDetails?.[index]
                                ?.imageUrl) && (
                              <div>
                                <FileOutlined className="file-img" />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {additionalPdfs?.[field.name] &&
                            additionalPdfs?.[field.name]?.name ? (
                              <div>
                                <FileOutlined className="file-img" />
                              </div>
                            ) : (
                              <h2 className="d-flex align-center justify-center">
                                Upload PDF Here
                              </h2>
                            )}
                          </>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <div flex="none">
                    <Tooltip title="Remove pdf">
                      <CommonButton
                        type="link"
                        danger
                        onClick={() => {
                          remove(field.name);
                          removeImage(
                            additionalPdfs,
                            (i) => i === additionalPdfs[field.name],
                          );
                        }}
                        className="remove-upload-file"
                      >
                        <DeleteOutlined />
                      </CommonButton>
                    </Tooltip>
                  </div>
                </Row>
              ))}
              <Form.Item>
                <Row>
                  <h4
                    className="pointer"
                    onClick={() => {
                      if (fields.length > 4) {
                        message.error('You can not upload more than 5 pdfs');
                      } else {
                        add();
                      }
                    }}
                  >
                    + Add Pdf
                  </h4>
                </Row>
              </Form.Item>
            </div>
          )}
        </Form.List> */
        }

        {
          // comment for future use
          /* {(isSpecialEvent ||
          eventType === EVENT_TYPES.SUMMIT ||
          data?.event?.data?.ministries) && (
          <Row>
            <Col span={24}>
              <div className="d-flex flex-row align-center justify-between">
                <h4 className="mb-0">Presenting Ministries</h4>
                <Tooltip title="Add Ministry">
                  <CommonButton
                    type="primary"
                    className="mr-3"
                    onClick={() => {
                      onAddMinistry();
                    }}
                  >
                    Add Ministry
                  </CommonButton>
                </Tooltip>
              </div>
              <Divider />
            </Col>
          </Row>
        )} */
        }

        {
          // comment for future use
          /* {(isSpecialEvent ||
          eventType === EVENT_TYPES.SUMMIT ||
          data?.event?.data?.ministries) && (
          <Row>
            <Col span={24}>
              <Form.Item
                name="ministries"
                label="Select Presenting Ministries"
                rules={[required]}
              >
                <CommonSelect
                  allowClear
                  mode="multiple"
                  filterOption={false}
                  placeholder="Select Presenting Ministries"
                  onSearch={debounceMinistrySearchHandler}
                  onClear={handleMinistryClear}
                  onPopupScroll={onMinistryScroll}
                  onBlur={handleMinistryBlur}
                  onSelect={handleMinistrySelect}
                  notFoundContent={
                    ministriesLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty />
                    )
                  }
                >
                  {map(ministries, (ministry) => (
                    <Option value={ministry?.id} key={ministry?.id}>
                      {ministry?.name}
                    </Option>
                  ))}
                </CommonSelect>
              </Form.Item>
            </Col>
          </Row>
        )} */
        }

        {
          // comment for future use
          /* {(isSpecialEvent ||
          eventType === EVENT_TYPES.SUMMIT ||
          data?.event?.data?.ministries) && (
          <div>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="feedbackStartDate"
                  label="Feedback Start Date"
                  rules={[required]}
                >
                  <DatePicker
                    showToday={false}
                    disabledDate={(current) =>
                      current < moment().startOf('day')
                    }
                    className="col"
                    format={DEFAULT_DATE_FORMAT}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="feedbackCloseDate"
                  label="Feedback End Date"
                  rules={[required]}
                >
                  <DatePicker
                    showToday={false}
                    disabledDate={(current) =>
                      current < moment().startOf('day')
                    }
                    className="col"
                    format={DEFAULT_DATE_FORMAT}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )} */
        }
        {
          // comment for future use
          /* {(data?.event?.data?.isDonationFee || donationFee) && (
          <Row>
            <Col span={24}>
              <Form.Item
                name="donationUrl"
                label="Donation URL"
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (
                        trimStart(value) &&
                        !trimStart(value).match(REGEX.WEB_URL)
                      ) {
                        return Promise.reject(
                          new Error('should be a valid url'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <CommonInput placeholder="Donation URL" allowClear />
              </Form.Item>
            </Col>
          </Row>
        )} */
        }
        <Row className="justify-end mt-8">
          <Col>
            <Tooltip title="Cancel">
              <CommonButton
                className="mr-8"
                onClick={() =>
                  history.push(`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}`)
                }
              >
                Cancel
              </CommonButton>
            </Tooltip>
            <Tooltip
              title={
                (isEdit && 'Update Event') ||
                (!eventId && 'Create Event') ||
                (isDuplicate && 'Duplicate Event')
              }
            >
              <CommonButton
                type="primary"
                htmlType="submit"
                loading={uploading}
                disabled={btnDisable}
              >
                {isEdit && 'Update Event'}
                {!eventId && 'Create Event'}
                {isDuplicate && 'Duplicate Event'}
              </CommonButton>
            </Tooltip>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EventForm;
