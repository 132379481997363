import { CopyOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Form, Input, Row, message } from 'antd';
import { isEmpty } from 'lodash';
import React, { useContext, useRef } from 'react';
import { AppContext } from '../../AppContext';
import { LOGICWIND_URL } from '../../common/constants';
import EmailNote from './EmailNote';
import LogicwindSignature from './LogicwindSignature';

const initialValues = {
  displayName: 'John Doe',
  designation: 'ui developer',
  number: '9498872872',
  profileUrl: 'https://placehold.co/105x105?text=Profile\nimage',
};

function EmailDesign() {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const displayName = Form.useWatch(['displayName'], form);
  const designation = Form.useWatch(['designation'], form);
  const number = Form.useWatch(['number'], form);
  const profileUrl = Form.useWatch(['profileUrl'], form);

  const userInitial = {
    displayName: currentUser?.displayName,
    designation: currentUser?.jobTitle,
    number: currentUser?.mobileNo,
    profileUrl: `${currentUser?.profileImage}?time=${Date.now()}`,
  };

  const contentRef = useRef(null);

  const handleCopy = () => {
    const contentToCopy = contentRef.current;
    if (contentToCopy) {
      // eslint-disable-next-line no-undef
      const range = document.createRange();
      range.selectNode(contentToCopy);
      // eslint-disable-next-line no-undef
      window.getSelection().removeAllRanges();
      // eslint-disable-next-line no-undef
      window.getSelection().addRange(range);

      try {
        // eslint-disable-next-line no-undef
        document.execCommand('copy');
        message.success('Email signature copied to clipboard!');
      } catch (err) {
        // eslint-disable-next-line no-console
        console?.log(err);
      }
      // eslint-disable-next-line no-undef
      window.getSelection().removeAllRanges();
    }
  };

  return (
    <div className="email-signature-page">
      <Card
        className="ant-body-scroll"
        title="Email Signature"
        extra={
          <Button type="primary" onClick={handleCopy} icon={<CopyOutlined />}>
            Copy Signature
          </Button>
        }
      >
        <Form
          layout="vertical"
          name="basic"
          initialValues={!isEmpty(currentUser) ? userInitial : initialValues}
          autoComplete="off"
          form={form}
        >
          <div className="w-450 m-auto" ref={contentRef}>
            <LogicwindSignature
              profile={profileUrl || initialValues?.profileUrl}
              name={displayName || initialValues?.displayName}
              number={number || initialValues?.number}
              designation={designation || initialValues?.designation}
              website={LOGICWIND_URL}
            />
          </div>
          <Divider />
          <Row gutter={[16, 0]}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Profile Image (url)"
                name="profileUrl"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Your Profile url!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Name"
                name="displayName"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Your Name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Designation"
                name="designation"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Your Designation!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Mobile Number"
                type="number"
                name="number"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Your Mobile Number!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Website"
                name="web"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Your Website!',
                  },
                ]}
              >
                <Input defaultValue={LOGICWIND_URL} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider />

        <EmailNote />
      </Card>
    </div>
  );
}
export default EmailDesign;
