import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
  Tag,
  Typography,
} from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LIMIT } from '../../../common/constants';
import CommonSelect from '../../../components/CommonSelect';
import ModalRouterPrompt from '../../../components/ModalClosePrompt';
import { ALL_TAGS } from '../../users/graphql/Queries';
import { ADD_PROJECT } from '../graphql/Mutations';

const { Title } = Typography;

const ProjectModal = (props) => {
  const [form] = Form?.useForm();
  const { show, close, executeQuery } = props;
  const [tags, setTags] = useState([]);
  const defaultSkip = 0;
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [statusState, setStatusState] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isFormValueCahnge, setIsFormValueCahnge] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const [search, setSearch] = useState('');
  const [tagSearch, setTagSearch] = useState('');
  const inputTagWidth =
    inputValue?.length > 80 ? 'expanding-tag-width' : 'default-tag-width';

  const [addProject] = useMutation(ADD_PROJECT, {
    fetchPolicy: 'network-only',
    onError() {
      setLoading(false);
    },
    onCompleted: () => {
      setLoading(false);
      close();
      setInputVisible(false);
      form?.resetFields();
      executeQuery({
        variables: {
          filters: {
            skip: 0,
            limit: LIMIT,
            sortOrder: 'DESC',
            sortField: 'createdOn',
          },
        },
      });
    },
  });
  const [executeTagQuery] = useLazyQuery(ALL_TAGS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    executeTagQuery({
      variables: {
        searchString: search,
        filters: {
          skip: defaultSkip,
          limit: LIMIT,
        },
      },
    });
  }, []);

  const debouncedUpdateValue = useCallback(
    debounce((newValue) => {
      setTagSearch(newValue?.toLowerCase());
    }, 500),
    [],
  );

  const updateSearchValue = (newValue) => {
    setSearch(newValue?.toLowerCase());
  };

  const handleTagSearch = (searchText) => {
    debouncedUpdateValue(searchText);
    updateSearchValue(searchText);
  };

  const onFinish = (values) => {
    setLoading(true);
    addProject({
      variables: {
        input: {
          name: values?.name,
          clientName: values?.clientName,
          status: statusState ? 'ACTIVE' : 'INACTIVE',
          integrations: values?.integrations,
          tags:
            selectedItems?.length > 0 ? [...tags, ...selectedItems] : [...tags],
          coreMembers: values?.coreMembers,
          teamMembers: values?.teamMembers,
        },
      },
    });
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef?.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef?.current?.focus();
  }, [inputValue]);

  useEffect(() => {
    if (editInputRef?.current) {
      const inputElement = editInputRef?.current?.input;
      const textWidth = inputElement?.scrollWidth + 2;
      inputElement.style.width = `${Math?.max(20, textWidth)}px`;
    }
  }, [editInputValue, editInputIndex]);

  const handleClose = (removedTag) => {
    const newTags = tags?.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
    inputRef?.current?.focus();
  };

  const handleTagChange = (selectedTags) => {
    setSearch('');
    setTagSearch('');
    const duplicateTags = selectedTags?.filter((tag) => !tags?.includes(tag));
    setSelectedItems(duplicateTags);
  };

  const handleEditInputChange = (e) => {
    const editInputValues = e?.target?.value;
    setEditInputValue(editInputValues?.toLowerCase());
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue('');
  };

  const onCancel = () => {
    if (isFormValueCahnge) {
      setShowPrompt(true);
    } else {
      setInputVisible(false);
      form?.resetFields();
      close();
    }
  };

  const handleSwitch = (checked) => {
    setStatusState(checked);
  };

  return (
    <>
      <Modal
        title="Create Project"
        open={show}
        onCancel={onCancel}
        footer={null}
        centered
        destroyOnClose
      >
        <Spin spinning={loading}>
          <div className="form mt-32">
            <Form
              name="control-ref"
              form={form}
              onFinish={onFinish}
              layout="vertical"
              onFieldsChange={() => {
                setIsFormValueCahnge(true);
              }}
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="name"
                    label="Project Name:"
                    rules={[
                      { required: true, message: 'Please Input Project Name!' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="clientName"
                    label="Client Name:"
                    rules={[
                      { required: true, message: 'Please Input Client Name!' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} xl={8}>
                  <Form.Item
                    name="status"
                    label="Status"
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      defaultChecked={statusState}
                      onChange={handleSwitch}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Title level={5} className="mt-0">
                Integrations
              </Title>
              <Form.List name="integrations">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map((field, index) => (
                      <Space key={field?.key} align="baseline">
                        <Form.Item
                          key={field?.index}
                          name={[field?.name, 'referenceName']}
                          rules={[
                            {
                              required: true,
                              message: 'Please Add Reference Name',
                            },
                          ]}
                        >
                          <Input placeholder={`Reference Name ${index + 1}:`} />
                        </Form.Item>
                        <Form.Item
                          key={field?.index}
                          name={[field?.name, 'referenceId']}
                          rules={[
                            {
                              required: true,
                              message: 'Please Add Reference Id',
                            },
                          ]}
                        >
                          <Input placeholder={`Reference Id ${index + 1}:`} />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => remove(field?.name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Title level={5}>Assign Tags</Title>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Form.Item name="tags">
                    <Space size={[0, 8]} wrap>
                      <Space size={[0, 8]} wrap>
                        {tags?.map((tag, index) => {
                          if (editInputIndex === index) {
                            return (
                              <Input
                                ref={editInputRef}
                                key={tag}
                                size="small"
                                className={inputTagWidth}
                                value={editInputValue}
                                onChange={handleEditInputChange}
                                onBlur={handleEditInputConfirm}
                                onPressEnter={handleEditInputConfirm}
                                autoFocus
                              />
                            );
                          }
                          const tagElem = (
                            <Tag
                              key={tag}
                              closeIcon
                              onClose={() => handleClose(tag)}
                            >
                              <span
                                onDoubleClick={(e) => {
                                  setEditInputIndex(index);
                                  setEditInputValue(tag);
                                  e?.preventDefault();
                                }}
                              >
                                {tag}
                              </span>
                            </Tag>
                          );
                          return tagElem;
                        })}
                      </Space>
                      {inputVisible ? (
                        <>
                          <CommonSelect
                            inputRef={inputRef}
                            mode="tags"
                            placeholder="Search Tags"
                            className="user-tags"
                            defaultOpen={inputVisible}
                            query={ALL_TAGS}
                            onChange={handleTagChange}
                            onSearch={(searchText) => {
                              handleTagSearch(searchText);
                            }}
                            variables={{
                              searchString: tagSearch,
                              filters: {
                                tagsToExclude: tags,
                              },
                            }}
                            useEffectDeps={[tagSearch]}
                            responsePath="searchTags.tags"
                            valuePath="name"
                            labelPath="name"
                            fetchPolicy="network-only"
                            searchValue={search}
                            virtual={false}
                          />
                        </>
                      ) : (
                        <Tag onClick={showInput} className="add-new-tag">
                          <PlusOutlined /> New Tag
                        </Tag>
                      )}
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                wrapperCol={{
                  offset: 9,
                }}
              >
                <Button type="primary" htmlType="submit" disabled={loading}>
                  Add Project
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
        <ModalRouterPrompt
          open={showPrompt}
          handleOK={() => {
            setShowPrompt(false);
            setIsFormValueCahnge(false);
            form?.resetFields();
            close();
          }}
          handleCancel={() => setShowPrompt(false)}
        />
      </Modal>
    </>
  );
};

export default ProjectModal;
