import { DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import {
  ACTIONS,
  BACKEND_DATE_FORMAT,
  DATE_FORMAT,
  LABEL_FORMAT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  PUSHER_EVENTS,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import LoaderComponent from '../../components/LoaderComponent';
import {
  subscribeToResourceAllocationEvents,
  unsubscribeFromResourceAllocationEvents,
} from '../../components/PusherEvents';
import VerifyPermissions from '../../components/VerifyPermissions';
import { ALL_TAGS } from '../users/graphql/Queries';
import ResourceModal from './components/ResourceModal';
import UserModal from './components/UserModal';
import {
  CREATE_QUARTER_DISTRIBUTION,
  CREATE_QUARTER_RESOURCE,
  DELETE_QUARTER_DISTRIBUTION,
  UPDATE_QUARTER_DISTRIBUTION,
} from './graphql/Mutation';
import { QUARTER_RESOURCE_LISTING } from './graphql/Queries';

const QuarterView = () => {
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  const [form] = Form?.useForm();
  const [resourceForm] = Form?.useForm();
  const [openGroupsProject, setOpenGroupsProject] = useState({});
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [resourceModalVisible, setResourceModalVisible] = useState(false);
  const [resourceCreate, setResourceCreate] = useState(false);
  const [canvasClickInfo, setCanvasClickInfo] = useState('');
  const [project, setProject] = useState({});
  const [itemClickInfo, setItemClickInfo] = useState('');
  const [newGroupsProject, setNewGroupsProject] = useState([]);
  const [search, setSearch] = useState('');
  const [tagSearch, setTagSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [monthRange, setMonthRange] = useState([
    dayjs()?.startOf('month'),
    dayjs()?.add(2, 'months')?.endOf('month'),
  ]);

  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.QUARTER_VIEW,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });

  const [quarterViewListing, { loading, refetch }] = useLazyQuery(
    QUARTER_RESOURCE_LISTING,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const transformedGroups = [];
        const transformedItems = [];
        res?.quarterlyDistributions?.projects?.forEach((group) => {
          transformedGroups?.push({
            id: group?.id,
            title: group?.name,
            root: true,
            parent: group?.id,
          });
          group?.userQuarterlyResourceAllocation?.forEach((user) => {
            transformedGroups?.push({
              id: user?.quarterViewId,
              title:
                user?.displayName || `${user?.firstName} ${user?.lastName}`,
              root: false,
              parent: group?.id,
              quarterViewId: user?.quarterViewId,
              totalHours: user?.totalHours,
            });
            user?.quarterlyDistributions?.forEach((week) => {
              const startDate = dayjs(week?.startDate)?.startOf('day');
              const endDate = dayjs(week?.endDate)?.endOf('day');
              transformedItems?.push({
                id: week?.id,
                group: user?.quarterViewId,
                title: week?.hours,
                start_time: startDate,
                end_time: endDate,
              });
            });
          });
        });
        setGroups(transformedGroups);
        setItems(transformedItems);
      },
      onError: () => {},
    },
  );

  const [createQuarterResource] = useMutation(CREATE_QUARTER_RESOURCE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      refetch();
      setResourceCreate(false);
    },
    onError() {},
  });

  const [updateQuarterView] = useMutation(UPDATE_QUARTER_DISTRIBUTION, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      refetch();
      setResourceCreate(false);
    },
    onError() {},
  });

  const [deleteQuarterView] = useMutation(DELETE_QUARTER_DISTRIBUTION, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      refetch();
      setResourceModalVisible(false);
      resourceForm?.resetFields();
      setResourceCreate(false);
      setItemClickInfo('');
      setCanvasClickInfo('');
    },
    onError() {},
  });

  const [createQuarterView] = useMutation(CREATE_QUARTER_DISTRIBUTION, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      refetch();
    },
    onError() {},
  });

  useEffect(() => {
    quarterViewListing({
      variables: {
        filters: {
          search: selectedItems?.length > 0 ? selectedItems : [],
          startDate: monthRange?.[0]?.format(BACKEND_DATE_FORMAT),
          endDate: monthRange?.[1]?.format(BACKEND_DATE_FORMAT),
        },
      },
    });
  }, [monthRange]);

  const toggleGroupProject = (id) => {
    setOpenGroupsProject({
      ...openGroupsProject,
      [id]: !openGroupsProject?.[id],
    });
  };

  const handleOpenUserModal = (group) => {
    setUserModalVisible(true);
    setProject(group);
  };
  useEffect(() => {
    const channel = subscribeToResourceAllocationEvents({
      channelName: PUSHER_EVENTS.QUARTERLY_DISTRIBUTION,
      handlers: {
        handleQuarterlyDistribution: (data) => {
          const updatedItems = items?.filter(
            (item) => item?.id !== data?.quarterlyDistributionId,
          );
          setGroups((prevGroups) =>
            prevGroups?.map((item) =>
              item?.quarterViewId === data?.quarterViewId
                ? {
                    ...item,
                    totalHours: data?.totalHours,
                  }
                : item,
            ),
          );
          if (data?.action === ACTIONS?.[0]?.value) {
            setItems((prevItems) => [
              ...prevItems,
              {
                id: data?.quarterlyDistributionId,
                group: data?.quarterViewId,
                title: data?.hours,
                start_time: dayjs(data?.startDate)?.startOf('day'),
                end_time: dayjs(data?.endDate)?.endOf('day'),
              },
            ]);
          }
          if (data?.action === ACTIONS?.[1]?.value) {
            setItems((prevItems) =>
              prevItems?.map((item) =>
                item?.id === data?.quarterlyDistributionId
                  ? {
                      ...item,
                      title: data?.hours,
                      start_time: dayjs(data?.startDate)?.startOf('day'),
                      end_time: dayjs(data?.endDate)?.endOf('day'),
                    }
                  : item,
              ),
            );
          }
          if (data?.action === ACTIONS?.[2]?.value) {
            setItems(updatedItems);
          }
        },
      },
    });

    return () => {
      unsubscribeFromResourceAllocationEvents(channel);
    };
  }, [items]);

  useEffect(() => {
    const updatedGroupsProject = groups
      ?.filter((item) => item?.root || openGroupsProject[item?.parent])
      ?.map((group) => ({
        ...group,
        title: group?.root ? (
          <div
            className="quarter-view-sidebar"
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              toggleGroupProject(group?.id);
            }}
          >
            <Tooltip title={group?.title}>
              <span className="project-header">{group?.title}</span>
            </Tooltip>
            <div className="sidebar-btn">
              <Button
                key="1"
                className="add-user-resource-btn"
                type="primary"
                icon={<PlusOutlined />}
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  handleOpenUserModal(group);
                }}
              />
              {openGroupsProject[group?.id] ? (
                <UpOutlined />
              ) : (
                <div className="add-user-modal">
                  <DownOutlined />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="sidebar-user-name">
            {group?.title} : {group?.totalHours > 0 ? group?.totalHours : '-'}
          </div>
        ),
      }));
    setNewGroupsProject(updatedGroupsProject);
  }, [groups, openGroupsProject, items]);

  const handleItemClick = (itemId) => {
    setItemClickInfo(itemId);
    setResourceModalVisible(true);
    setResourceCreate(false);
  };

  const handleItemResize = (itemId, time, edge) => {
    const isBefore = dayjs(time)?.isBefore(dayjs());
    const resizeItem = items?.find((item) => item?.id === itemId);

    let newStartDate;
    if (isBefore && edge === 'left') {
      newStartDate = dayjs();
    } else if (edge === 'left') {
      newStartDate = dayjs(time);
    } else {
      newStartDate = dayjs(resizeItem?.start_time)?.format(BACKEND_DATE_FORMAT);
    }
    updateQuarterView({
      variables: {
        data: {
          quarterlyDistributionId: resizeItem?.id,
          startDate: newStartDate,
          endDate:
            edge === 'left'
              ? dayjs(resizeItem?.end_time)?.format(BACKEND_DATE_FORMAT)
              : dayjs(time),
          hours: parseInt(resizeItem?.title, 10),
          pusherStartDate: monthRange?.[0]?.format(BACKEND_DATE_FORMAT),
          pusherEndDate: monthRange?.[1]?.format(BACKEND_DATE_FORMAT),
        },
      },
    });
  };

  const handleCanvasClick = (childId) => {
    const result = groups?.find(
      (group) => !group?.root && group?.id === childId,
    );
    if (result?.id) {
      setCanvasClickInfo(childId);
      setResourceModalVisible(true);
      setResourceCreate(true);
    }
  };

  const handleOk = () => {
    setProject({});
    form?.submit();
  };

  const handleCancel = () => {
    setUserModalVisible(false);
    setProject({});
    form?.resetFields();
  };

  const handleResourceOk = (key) => {
    const child = items?.find((item) => item?.id === itemClickInfo);
    if (key === ACTIONS?.[2]?.value?.toLowerCase()) {
      deleteQuarterView({
        variables: {
          where: {
            quarterlyDistributionId: child?.id,
            pusherStartDate: monthRange?.[0]?.format(BACKEND_DATE_FORMAT),
            pusherEndDate: monthRange?.[1]?.format(BACKEND_DATE_FORMAT),
          },
        },
      });
    } else {
      resourceForm?.submit();
    }
  };

  const handleResourceCancel = () => {
    setResourceModalVisible(false);
    resourceForm?.resetFields();
  };

  const onFinish = (values) => {
    createQuarterResource({
      variables: {
        data: {
          projectId: project?.id || values?.projectName,
          userIds: values?.userName,
        },
      },
    });
    setUserModalVisible(false);
    setProject({});
    form?.resetFields();
  };

  const onResourceFinish = (values) => {
    const child = resourceCreate
      ? groups?.find((group) => group?.id === canvasClickInfo)
      : items?.find((item) => item?.id === itemClickInfo);
    const startDate = dayjs(values?.resourceTime?.[0])?.format(
      BACKEND_DATE_FORMAT,
    );
    const endDate = dayjs(values?.resourceTime?.[1])?.format(
      BACKEND_DATE_FORMAT,
    );

    if (child?.id === canvasClickInfo) {
      createQuarterView({
        variables: {
          data: {
            quarterViewId: child?.quarterViewId,
            startDate,
            endDate,
            hours: parseInt(values?.title, 10),
            pusherStartDate: monthRange?.[0]?.format(BACKEND_DATE_FORMAT),
            pusherEndDate: monthRange?.[1]?.format(BACKEND_DATE_FORMAT),
          },
        },
      });
    } else {
      updateQuarterView({
        variables: {
          data: {
            quarterlyDistributionId: child?.id,
            startDate,
            endDate,
            hours: parseInt(values?.title, 10),
            pusherStartDate: monthRange?.[0]?.format(BACKEND_DATE_FORMAT),
            pusherEndDate: monthRange?.[1]?.format(BACKEND_DATE_FORMAT),
          },
        },
      });
    }
    resourceForm?.resetFields();
    setResourceModalVisible(false);
    setResourceCreate(false);
    setItemClickInfo('');
    setCanvasClickInfo('');
  };

  const intervalRenderer = ({ getIntervalProps, intervalContext }) => {
    const isMonday =
      intervalContext?.intervalText &&
      dayjs(intervalContext?.intervalText, LABEL_FORMAT)?.day() === 1;

    if (isMonday) {
      return (
        <div className="week-label-format" {...getIntervalProps()}>
          {dayjs(intervalContext?.intervalText, LABEL_FORMAT)?.format(
            DATE_FORMAT,
          )}
        </div>
      );
    }
    return null;
  };

  const handleNextMonth = () => {
    const nextStartQuarter = monthRange[0]
      ?.clone()
      ?.add(1, 'months')
      ?.startOf('months');
    const nextEndQuarter = monthRange[1]
      ?.clone()
      ?.add(1, 'months')
      ?.endOf('months');
    setMonthRange([nextStartQuarter, nextEndQuarter]);
  };

  const handlePrevMonth = () => {
    const prevStartQuarter = monthRange[0]
      ?.clone()
      ?.subtract(1, 'months')
      ?.startOf('months');
    const prevEndQuarter = monthRange[1]
      ?.clone()
      ?.subtract(1, 'months')
      ?.endOf('months');
    setMonthRange([prevStartQuarter, prevEndQuarter]);
  };

  const handleTagChange = (selectedTags) => {
    setSearch('');
    setTagSearch('');
    setSelectedItems(selectedTags);
    quarterViewListing({
      variables: {
        filters: {
          search: selectedTags?.length > 0 ? selectedTags : [],
          startDate: monthRange?.[0]?.format(BACKEND_DATE_FORMAT),
          endDate: monthRange?.[1]?.format(BACKEND_DATE_FORMAT),
        },
      },
    });
  };

  const debouncedUpdateValue = useCallback(
    debounce((newValue) => {
      setTagSearch(newValue?.toLowerCase());
    }, 500),
    [],
  );

  const updateSearchValue = (newValue) => {
    setSearch(newValue?.toLowerCase());
  };

  const handleTagSearch = (searchText) => {
    debouncedUpdateValue(searchText);
    updateSearchValue(searchText);
  };

  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }

    return <span>+ {omittedValues?.length} more...</span>;
  };

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll quarter-view-card"
        title="Quarter Resource Planning"
        extra={
          <div className="project-filter-right">
            <div className="project-filter">
              {isCreatePermission && (
                <Button
                  key="1"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setUserModalVisible(true)}
                >
                  Add Resource
                </Button>
              )}
              <Button type="primary" onClick={handlePrevMonth}>
                Prev Month
              </Button>
              <Button type="primary" onClick={handleNextMonth}>
                Next Month
              </Button>
              <CommonSelect
                mode="tags"
                placeholder="Search Tags"
                className="resource-allocation-tags"
                allowClear
                query={ALL_TAGS}
                onChange={handleTagChange}
                onSearch={(searchText) => {
                  handleTagSearch(searchText);
                }}
                variables={{
                  searchString: tagSearch,
                }}
                useEffectDeps={[tagSearch]}
                responsePath="searchTags.tags"
                valuePath="name"
                labelPath="name"
                fetchPolicy="network-only"
                maxTagCount={3}
                maxTagPlaceholder={renderMaxTag}
                searchValue={search}
                virtual={false}
              />
            </div>
          </div>
        }
      >
        <div>
          <LoaderComponent size="default" spinning={loading}>
            <Timeline
              key={Date?.now()}
              groups={newGroupsProject}
              items={items}
              visibleTimeStart={monthRange?.[0]}
              visibleTimeEnd={monthRange?.[1]}
              canResize="both"
              lineHeight={40}
              sidebarWidth={230}
              itemHeightRatio={0.75}
              timeSteps={{
                month: 1,
                year: 1,
              }}
              onItemClick={(itemId) => handleItemClick(itemId)}
              onItemResize={(itemId, time, edge) =>
                handleItemResize(itemId, time, edge)
              }
              onCanvasClick={handleCanvasClick}
            >
              <TimelineHeaders className="resource-timeline-header">
                <SidebarHeader>
                  {({ getRootProps }) => (
                    <>
                      <div className="resource-title" {...getRootProps()}>
                        Quarter Resource
                      </div>
                    </>
                  )}
                </SidebarHeader>
                <DateHeader unit="primaryHeader" />
                <DateHeader />
                <DateHeader unit="day" />
                <DateHeader
                  unit="day"
                  labelFormat={LABEL_FORMAT}
                  className="custom-week-label"
                  intervalRenderer={intervalRenderer}
                />
              </TimelineHeaders>
            </Timeline>
          </LoaderComponent>

          {userModalVisible && (
            <UserModal
              userModalVisible={userModalVisible}
              handleOk={handleOk}
              handleCancel={handleCancel}
              groups={groups}
              onFinish={onFinish}
              form={form}
              project={project}
            />
          )}
          {resourceModalVisible && (
            <ResourceModal
              resourceModalVisible={resourceModalVisible}
              handleResourceOk={handleResourceOk}
              handleResourceCancel={handleResourceCancel}
              groups={groups}
              onResourceFinish={onResourceFinish}
              resourceForm={resourceForm}
              resourceCreate={resourceCreate}
              items={items}
              itemClickInfo={itemClickInfo}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default QuarterView;
