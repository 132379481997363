import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { capitalize, startCase, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
  SORT_ORDER,
  UPLOADED_FROM,
  WEB,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import { CREATE_ROLE, UPDATE_ROLE } from './graphql/Mutations';
import { MODULE_LISTING, ROLE_LISTING } from './graphql/Queries';

const AddEditRole = ({ history }) => {
  const { roleById } = useParams();
  const [form] = Form?.useForm();
  const [selectedPlatform, setSelectedPlatform] = useState(WEB);
  const [permissionsChanges, setPermissionsChanges] = useState({});
  const { dispatch } = useContext(AppContext);
  const [btnDisable, setBtnDisable] = useState(true);

  const [createRole] = useMutation(CREATE_ROLE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      history?.push({
        pathname: `${ROUTES_MODULES_KEY?.ROLE}`,
      });
    },
    onError: () => {},
  });

  const [executeQuery, { loading }] = useLazyQuery(MODULE_LISTING, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const newData = res?.modules?.data?.map((mappedModule) => ({
        moduleId: mappedModule?.id,
        access: {
          read: false,
          create: false,
          update: false,
          delete: false,
          send: false,
        },
        ...mappedModule,
      }));

      setPermissionsChanges(() => uniqBy([...newData], 'moduleId'));
    },
    onError: () => {},
  });
  const permissionsFilteredDataBasedOnPlatform =
    permissionsChanges?.length > 0 &&
    permissionsChanges?.filter((item) => item?.platform === selectedPlatform);

  const [executeRoleListingQuery, { loading: roleLoading, data: roleData }] =
    useLazyQuery(ROLE_LISTING, {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const formattedPermissions = res?.rolePermission?.permissions?.map(
          (mappedModule) => ({
            moduleId: mappedModule?.moduleId,
            access: {
              create: mappedModule?.access?.create || false,
              delete: mappedModule?.access?.delete || false,
              read: mappedModule?.access?.read || false,
              update: mappedModule?.access?.update || false,
              send: mappedModule?.access?.send || false,
            },
            ...mappedModule,
          }),
        );

        setPermissionsChanges(() =>
          uniqBy([...formattedPermissions], 'moduleId'),
        );
      },
      onError: () => {},
    });

  const [updateRole] = useMutation(UPDATE_ROLE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      history?.push({
        pathname: `${ROUTES_MODULES_KEY?.ROLE}`,
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    if (roleById) {
      executeRoleListingQuery({
        variables: {
          where: {
            id: roleById,
          },
        },
      });
    } else {
      executeQuery({
        variables: {
          filters: {
            sortOrder: SORT_ORDER?.[0]?.value,
          },
        },
      });
    }
  }, []);

  const handlePermissionChange = (selectedValues, moduleId) => {
    setBtnDisable(true);
    setPermissionsChanges((prevChanges) => {
      const updatedChanges = [...prevChanges];
      const moduleIndex = updatedChanges?.findIndex(
        (module) => module?.moduleId === moduleId,
      );
      if (moduleIndex !== -1) {
        const newAccess = { ...updatedChanges[moduleIndex]?.access };
        Object?.values(PERMISSION_OBJECT)?.forEach((value) => {
          newAccess[value] = selectedValues?.includes(value);
        });
        updatedChanges[moduleIndex] = {
          ...updatedChanges[moduleIndex],
          access: newAccess,
        };
      }
      return updatedChanges;
    });
  };

  useEffect(() => {
    form?.setFieldsValue({
      name: roleData?.rolePermission?.name,
    });
  }, [roleData]);

  const handleCancel = () => {
    history?.push(`${ROUTES_MODULES_KEY?.ROLE}`);
  };
  const handleBack = () => {
    history?.push(`${ROUTES_MODULES_KEY?.ROLE}`);
  };
  const handleSave = () => {
    form?.submit();
  };

  const onFinish = async ({ name }) => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const permissionsChangesData = permissionsChanges?.map((elem) => ({
      moduleId: elem?.moduleId,
      access: elem?.access,
    }));
    try {
      if (roleById) {
        await updateRole({
          variables: {
            where: {
              id: roleById,
            },
            input: {
              name,
              permissions: permissionsChangesData,
            },
          },
        });
      } else {
        await createRole({
          variables: {
            input: {
              name,
              permissions: permissionsChangesData,
            },
          },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('Error:', error);
    }
    setBtnDisable(false);
  };

  const columns = [
    {
      title: 'Module Name',
      align: 'center',
      width: '29%',
      render: (_, record) => (
        <span>{startCase(capitalize(record?.name || record?.moduleName))}</span>
      ),
    },
    {
      title: 'Access Level',
      dataIndex: 'config',
      align: 'center',
      width: '71%',
      render: (_, record) => (
        <Checkbox.Group
          onChange={(e) => {
            handlePermissionChange(e, record?.id || record?.moduleId);
          }}
          value={Object.values(PERMISSION_OBJECT).filter(
            (key) => record?.access[PERMISSION_OBJECT[key]],
          )}
        >
          {Object?.values(PERMISSION_OBJECT)?.map((value) => (
            <Checkbox key={value} value={value}>
              {capitalize(value)}
            </Checkbox>
          ))}
        </Checkbox.Group>
      ),
    },
  ];

  return (
    <Card
      className="ant-body-scroll role-card"
      title={
        <div className="d-flex align-center">
          <Button
            type="text"
            shape="square"
            onClick={handleBack}
            icon={<ArrowLeftOutlined />}
          />
          {roleById ? 'Edit Role' : 'Create Role'}
        </div>
      }
      extra={
        <div className="listing-filter">
          <Select
            options={UPLOADED_FROM}
            defaultValue={UPLOADED_FROM[0]}
            onChange={(value) => {
              setSelectedPlatform(value);
            }}
          />
          <Button className="ml-10" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave} disabled={!btnDisable}>
            Save
          </Button>
        </div>
      }
    >
      <Row>
        <Col xs={24} md={16} lg={12}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            onFieldsChange={() => {
              setBtnDisable(false);
              dispatch({
                type: 'SET_SHOW_PROMPT',
                data: true,
              });
            }}
          >
            <div className="card-body-wrapper">
              <Col xs={24} lg={12} xl={7}>
                <Form.Item
                  label="Role Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Role Name!',
                    },
                  ]}
                >
                  <Input placeholder="Enter Role Name" className="ml-4" />
                </Form.Item>
              </Col>

              {permissionsChanges?.length > 0 && (
                <CommonTable
                  size="small"
                  columns={columns}
                  data={permissionsFilteredDataBasedOnPlatform}
                  loadingData={loading || roleLoading}
                  rowKey={(obj) => obj?.id}
                  bordered
                  scroll={{
                    y: '100%',
                  }}
                />
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default AddEditRole;
